/* src/css/LogoCarousel.css */

.logo-carousel .carousel-logo {
    transition: background-color 0.3s ease-in-out;
    padding: 15px;
    border-radius: 10px;
}

.logo-carousel .carousel-logo:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Hover background effect */
    padding: 20px; /* Increase padding slightly on hover */
}

@media (max-width: 768px) {
    .logo-carousel .carousel-logo {
        padding: 10px;
    }

    .logo-carousel .carousel-logo:hover {
        padding: 15px;
    }
}
