/* src/css/ShippingForm.css */

.progress-bar {
    height: 1.5rem;
    font-size: 1rem;
}

.text-danger {
    font-size: 0.875rem;
}

.form-control {
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.row {
    margin-bottom: 1rem;
}
