/* Homepage.css */

/* General styles for the HomePage */
.container {
    padding: 0 15px; /* This controls the global padding inside .container */
  }
  
  .hero, .featurette {
    margin-bottom: 30px;
  }
  
  /* Specific styles for Hero component */
  .hero-container {
    padding: 0; /* Remove padding from left, right, top, and bottom */
    margin: 0; /* Remove margin from left, right, top, and bottom */
  }
  
  .hero {
    text-align: center;
    padding: 40px 0; /* Optional padding for spacing inside hero */
  }
  
  .hero img {
    max-width: 100%;
    height: auto;
  }
  
  /* Styles for Featurette component */
  .featurette {
    padding: 40px 0; /* Keep featurette padding */
  }
  
  .featurette img {
    max-width: 100%;
    height: auto;
  }
  
  /* Media query for screens smaller than 768px (phones) */
  @media (max-width: 768px) {
    .hero {
      padding: 20px 0;
    }
  
    .featurette {
      padding: 20px 0;
    }
  }
  
  /* Media query for screens 768px and above (tablets and desktops) */
  @media (min-width: 768px) {
    .hero {
      padding: 40px 0;
    }
  
    .featurette {
      padding: 40px 0;
    }
  }
  