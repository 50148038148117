/* Remove any default margin or padding from the body and html */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;  /* Ensure no padding is applied to the body */
  overflow-x: hidden;  /* Prevent horizontal scrolling */
}

.hero-container {
  background-image: url('../app/assets/img/generalImg/heroFrontPageImg1.jpg'); /* Replace with your image path */
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  color: white; /* Set text color to contrast with the background */
  height: 100vh; /* Make the container take up the entire viewport height */
  display: flex; /* Flexbox for centering content */
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  padding-top: 10rem; /* Optional padding for spacing */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove any margin */
  width: 100%; /* Ensure container is full width */
}

.hero-heading {
  font-size: 3rem; /* Larger font size for full-screen hero */
  margin-bottom: 1rem;
  margin-top: 5rem; /* Add space from the top */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Add text shadow for readability */
  color: rgb(0, 0, 0); /* Set text color to black */
}

.hero-lead {
  font-size: 1.5rem; /* Adjust lead text size */
  margin-bottom: 10rem;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); /* Add text shadow for readability */
  color: black; /* Set text color to black */
}

.hero-buttons {
  margin-bottom: 2rem;
}

.featurette-divider {
  margin-top: 2rem;
  display: none; /* Hide divider for full-screen layout */
}

@media (max-width: 768px) {
  .hero-heading {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  .hero-lead {
    font-size: 1rem; /* Adjust lead text size for smaller screens */
    margin-bottom: 95px !important; /* Add margin for phone screens */
  }

  .hero-buttons {
    margin-bottom: 1.5rem;
  }
}
