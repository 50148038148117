/* src/css/CustomWhatsAppWidget.css */

/* Ensure all parent containers are properly stacked */
.react-whatsapp-widget-container,
.react-whatsapp-widget-container *,
.react-whatsapp-widget-button {
  z-index: 9999 !important;  /* Force a high z-index on the widget and its button */
  position: fixed !important;  /* Ensure it stays in place */
}

/* Specifically target the widget button if needed */
.react-whatsapp-widget-button {
  z-index: 9999 !important;  /* Set a high z-index for the button */
  position: fixed !important;  /* Make sure it's fixed in position */
}