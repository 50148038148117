/* Featurette.css */

.featurette {
  margin-bottom: 1.5rem; /* Reduced margin bottom for featurettes */
  margin-top: -4.0rem;
}

.featurette-heading {
  font-size: 2rem;
  margin-bottom: 1rem; /* Reduced margin bottom for heading */
  
}

.featurette-video {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem; /* Reduced margin bottom for video */
 
}

.featurette-image {
  width: 100%;
  height: auto;
  margin-bottom: 1rem; /* Reduced margin bottom for image */
  
}

.featurette-divider {
  margin: 0rem 0; /* Reduced margin above and below each divider */
  
}

@media (max-width: 768px) {
  .featurette {
    text-align: center;
    margin-bottom: 0rem; /* Reduced margin bottom for featurettes on small screens */
    margin-top: -4.0rem;
  }

  .featurette-heading {
    font-size: 1.5rem;
    margin-bottom: 0.75rem; /* Reduced margin bottom for heading on small screens */
    
  }

  .featurette-video {
    width: 100%;
    height: 100%;
    margin-bottom: 0.0rem; /* Reduced margin bottom for video on small screens */
    z-index: 1;
  }

  .featurette-image {
    max-width: 100%;
    height: auto;
    margin-bottom: -4.0rem; /* Reduced margin bottom for image on small screens */
    z-index: 1;
    
  }

  .featurette-divider {
    margin: 0.75rem 0; /* Reduced margin above and below each divider on small screens */
  }
}
