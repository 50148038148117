/* src/css/Employees.css */

.employee-img {
    display: block; /* Center the image horizontally */
    margin: 0 auto; /* Center the image horizontally */
    z-index: 1; /* Ensure the image stays above lower layers */
    position: relative; /* Position the image relative to its container */
  }
  
  .card-body {
    text-align: center; /* Center text below the image */
    z-index: 1; /* Ensure the text stays above lower layers */
    position: relative; /* Position the text relative to its container */
}

  .employee-card {
    z-index: 1; /* Ensure the entire card has a z-index of 1 */
    position: relative; /* Position the card relative to its container */
    z-index: 1; /* Set the z-index for the entire row */
  }
  
  .employee-row {
  position: relative; /* Ensure z-index works */
  z-index: 1; /* Set the z-index for the entire row */
}