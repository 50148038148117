.table td {
  padding: 0; /* Remove default padding */
}

.form-control {
  margin: 0; /* Remove margin */
  padding: 0.375rem 0.75rem; /* Adjust padding inside input fields */
}

.input-group {
  margin: 0; /* Remove margin from input group */
}

.input-group-prepend, .input-group-append {
  margin: 0; /* Remove margin from input group prepend/append */
}

.featurette-divider {
  border: 0;
  border-top: 2px solid #ccc;  /* Light grey color for the line */
  margin: 30px 0;  /* Add space above and below the line */
}